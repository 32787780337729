/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	//Plugin Functions
	$.fn.fullWidth = function(method) {
		var viewportWidth = $(window).width();
		var obj = $(this);
		if(method === 'resize'){
			return obj.each(function() {
				$(this).css('marginLeft', '0').css('marginRight', '0');
				var width = $(this).width();
				var margin =  0 - ((viewportWidth - width) / 2);
				$(this).css('marginLeft', margin).css('marginRight', margin);
			});
		}
		$(window).on("resize", function(){
			obj.fullWidth('resize');
		});
		return obj.fullWidth('resize');
	};
	
	jQuery.easing.default = function (x,t,b,c,d) {
		return -c * ((t=t/d-1)*t*t*t - 1) + b;
	};  

	$.fn.visible = function(partial) {
	
		var $t            = $(this),
				$w            = $(window),
				viewTop       = $w.scrollTop(),
				viewBottom    = viewTop + $w.height(),
				_top          = $t.offset().top,
				_bottom       = _top + $t.height(),
				compareTop    = partial === true ? _bottom : _top,
				compareBottom = partial === true ? _top : _bottom;
	
		return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

	};

	$.fn.scrollSpeed = function(step, speed, easing) {
					
		var $document = $(document),
				$window = $(window),
				$body = $('html, body'),
				option = easing || 'default',
				root = 0,
				scroll = false,
				scrollY,
				view;
			
		$window.on("load", function(e){
			root = $window.scrollTop();
		});

		$window.on('mousewheel DOMMouseScroll', function(e) {

			var deltaY = e.originalEvent.wheelDelta,
				detail = e.originalEvent.detail;
				scrollY = document.body.clientHeight > $window.height();
				scroll = true;
			
			if (scrollY) {
				
				view = $window.height();
					
				if (deltaY < 0 || detail > 0) {
					root = (root + view) >= $document.height() ? root : root += step;
				}
				
				if (deltaY > 0 || detail < 0) {
					root = root <= 0 ? 0 : root -= step;
				}
				
				$body.stop().animate({
					scrollTop: root
				}, speed, option, function() {
					scroll = false;
				});
			}
			
			return false;
			
		}).on('scroll', function() {
			
			if (scrollY && !scroll) {
				root = $window.scrollTop();
			}
			
		}).on('resize', function() {
			
			if (scrollY && !scroll) {
				view = $window.height();
			}
		});       
	};
	
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages$(document).ready(function() {

				$(".navbar-nav").clone().insertAfter("#off-canvas .navbar-brand");

				$(function() {
					$(document).trigger("enhance");
				});
				

				$(window).scroll(function() {    
					var scroll = $(window).scrollTop();
					if($('body').hasClass('home')){
						if (scroll >= ($(window).height() * 0.8)) {
								$(".banner").addClass("shrink");
						} else {
								$(".banner").removeClass("shrink");
						}
					} else {
						if (scroll >= ($(window).height() * 0.4)) {
								$(".banner").addClass("shrink");
						} else {
								$(".banner").removeClass("shrink");
						}
					}
				});

				$(window).load(function(){

					$(".intro-animation").each(function(i, el) {
							var $el = $(el);
							if ($el.visible(true)) {
								$el.addClass("already-visible"); 
							} 
					});

					$(window).scroll(function(event) {
						
						$(".intro-animation").each(function(i, el) {
							var $el = $(el);
							if ($el.visible(true)) {
								$el.addClass("animate"); 
							} 
						});
						
					});

				});

				$('.full-width').fullWidth();
				
				if (!Modernizr.touchevents) { 
					
					//Not on Safari!
					if (navigator.userAgent.indexOf('Safari') === -1 || navigator.userAgent.indexOf('Chrome') > -1) {
						//$(document).scrollSpeed(100, 800);
					}

				}

				/*

				$('body').on('mouseenter mouseleave','.dropdown',function(e){
				  var _d=$(e.target).closest('.dropdown');_d.addClass('show');
				  if(_d.is(':hover')){
				  	_d.trigger('show.bs.dropdown');
				  } else {
				  	_d.trigger('hide.bs.dropdown');
				  }
				});

				*/


				$('.tooltip-init').tooltip();
				
				// Add slideDown animation to Bootstrap dropdown when expanding.
			  $('.dropdown').on('show.bs.dropdown', function() {
			    $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
			  });

			  // Add slideUp animation to Bootstrap dropdown when collapsing.
			  $('.dropdown').on('hide.bs.dropdown', function() {
			    $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
			  });

				//Initialise Carousel Instances
				if($('.owl-carousel').length){
					$.ajaxSetup({
						cache: true
					});
					$.get( asset_path + 'assets.json', function( assets ){
						$.getScript( asset_path + 'scripts/' + assets['owl.carousel.js'], function(){
							$('.owl-carousel').each(function(){
								var carouselOptions = {
									'items' 		: $(this).attr('data-items') 									 ? $(this).data('items') 		: 1,
									'loop' 			: $(this).attr('data-loop') 									 ? $(this).data('loop') 			: true,
									'center' 		: $(this).attr('data-center') 								 ? $(this).data('center') 		: true,
									'nav' 			: $(this).attr('data-nav')	=== "0"						 ? false											: true,
									'autoPlay' 	: $(this).attr('data-autoPlay')	=== "0"				 ? false											: true,
									'autoWidth'	: $(this).attr('data-autoWidth')							 ? true : false,
									'autoHeight'	: $(this).attr('data-autoHeight')	=== "0" 	 ? false											: true,

								};
								
								
								carouselOptions.sm = {
									'items'				: $(this).attr('data-sm-items') 								 ? $(this).data('sm-items') 	: carouselOptions.items,
									'loop' 				: $(this).attr('data-sm-loop') 									 ? $(this).data('sm-loop') 	: carouselOptions.loop,
									'center' 			: $(this).attr('data-sm-center') 								 ? $(this).data('sm-center') : carouselOptions.center,
									'nav' 				: $(this).attr('data-sm-nav')	=== "0"						 ? false											: carouselOptions.nav,
									'autoHeight'	: $(this).attr('data-sm-autoHeight')	=== "0"    ? false 											: carouselOptions.autoHeight
								};
								
							 carouselOptions.md = {
									'items'				: $(this).attr('data-md-items') 								 ? $(this).data('md-items') 	: carouselOptions.sm.items,
									'loop' 				: $(this).attr('data-md-loop') 									 ? $(this).data('md-loop') 	: carouselOptions.sm.loop,
									'center' 			: $(this).attr('data-md-center')								 ? $(this).data('md-center') : carouselOptions.sm.center,
									'nav' 				: $(this).attr('data-md-nav')	=== "0"						 ? false											: carouselOptions.sm.nav,
									'autoHeight'	: $(this).attr('data-md-autoHeight')	=== "0"  	 ? false 											: carouselOptions.sm.autoHeight
								};
								
								carouselOptions.lg	= {
									'items'				: $(this).attr('data-lg-items') 								 ? $(this).data('lg-items') 	: carouselOptions.md.items,
									'loop' 				: $(this).attr('data-lg-loop') 									 ? $(this).data('lg-loop') 	: carouselOptions.md.loop,
									'center' 			: $(this).attr('data-lg-center') 								 ? $(this).data('lg-center') : carouselOptions.md.center,
									'nav' 				: $(this).attr('data-lg-nav')	=== "0"						 ? false											: carouselOptions.md.nav,
									'autoHeight'	: $(this).attr('data-lg-autoHeight')	=== "0"  	 ? false 											: carouselOptions.md.autoHeight
								};
								var carousel = $(this).owlCarousel({
									lazyLoad: false,
									autoPlay: carouselOptions.autoPlay,
									loop: carouselOptions.loop,
									center: carouselOptions.center,
									nav: 		carouselOptions.nav,
									items: 	carouselOptions.items,
									autoWidth: carouselOptions.autoWidth,
									autoHeight: carouselOptions.autoHeight,
									navText: ['<div class="btn"><i class="icon icon-left-open-1"></i></div>','<div class="btn"><i class="icon icon-right-open-1"></i></div>'],
									responsive : {
										// breakpoint from 768 up
										768 : {
											center: carouselOptions.sm.center,
											nav: 		carouselOptions.sm.nav,
											autoHeight: carouselOptions.sm.autoHeight,
											items: 	carouselOptions.sm.items
										},
										// breakpoint from 980 up
										980 : {
											center: carouselOptions.md.center,
											nav: 		carouselOptions.md.nav,
											autoHeight: carouselOptions.md.autoHeight,
											items: 	carouselOptions.md.items
										},
										// breakpoint from 980 up
										1200 : {
											center: carouselOptions.lg.center,
											nav: 		carouselOptions.lg.nav,
											autoHeight: carouselOptions.lg.autoHeight,
											items: 	carouselOptions.lg.items
										}
									}
								});
								console.log(carouselOptions);
								
								if($(this).attr('data-start-item')){
									carousel.trigger('to.owl.carousel', parseInt($(this).attr('data-start-item')), 0); 
								}

								if($(this).attr('data-external-nav')){
									var external_nav = $(this).attr('data-external-nav');
									$(external_nav).on("click", function(event){
										event.preventDefault();
										$(this).parent().parent().find('.active').removeClass('active');
										$(this).addClass('active').parent().addClass('active');
										carousel.trigger('to.owl.carousel', parseInt($(this).attr('data-target')), 0); 
									});


									carousel.on('changed.owl.carousel', function(event) {
										$(external_nav).each(function(){
											if($(this).hasClass('active')) { $(this).removeClass('active'); }
											if($(this).data('target') === event.page.index) { $(this).addClass('active'); }
										});
									});

								}
							});
						});
					});
				}

				//Initialise Carousel Instances
				if($('.masonry-grid').length){
					$.ajaxSetup({
						cache: true
					});
					$.get( asset_path + 'assets.json', function( assets ){
						$.getScript( asset_path + 'scripts/' + assets['masonry.js'], function(){
							//$('.masonry-grid').masonry();
						});
					});
				}

				//Contact forms
				
				if($('.widget_cyberfox_contactformwidget').length){
					$('.widget_cyberfox_contactformwidget .contact-form-widget').each(function(){
						var $form = $(this);

						$form.find('input:first').hide();

						$form.find('.input').each(function() {
							console.log($form);
							$(this).attr('name','form_' + $form.data('widgetid') + '-' + $(this).attr('name'));
						});

						$form.find('.form-control').on("change", function(){
							var $input = $(this);
							$input.parents('.form-group').removeClass('has-success');
							$input.parents('.form-group').removeClass('has-warning');
							if($input[0].checkValidity() !== false){
								$input.parents('.form-group').addClass('has-success');
							} else {
								$input.parents('.form-group').addClass('has-warning');
							}
						});

						$form.on('submit', function(event){
							event.preventDefault();
							$form.addClass('was-validated');
							$form.find('[type="submit"]').prop('disabled', true);

							$.ajax({
								data: $form.serialize(),
								url: $form.attr('action'),
								method: 'post',
								dataType:  'json',
								error: function(){$form.prepend('<div class="alert alert-danger">'+$form.data('error')+'</div>');},
								success: function(data){
									$form.find('[type="submit"]').removeAttr('disabled');
									if(data.status === 0) {	
										$form.find('.alert-error').hide();
										if(data.error_msg !== null && data.error_msg !== '') {	
											$form.prepend('<div class="alert alert-danger">'+data.error_msg+'</div>');
											if(data.error_field !== null && data.error_field !== '') {
												$('#'+data.error_field).focus().parent().addClass('has-error');
											}
										} else {
											$form.prepend('<div class="alert alert-danger">'+$form.data('error')+'</div>');
										}
									} else {
										$form.html('<div class="alert alert-success">'+$form.data('success')+'</div>');	
									}
								}
							});
						});
					});
				}
				
				
				if($('.google-maps-init').length){
					var key = '';
					if ($('.google-maps-init').attr('data-key')) {
						key = '?key=' + $('.google-maps-init').data('key');
					}
					$.getScript('https://maps.googleapis.com/maps/api/js' + key, function(){
						//GMAP
						$('.google-maps-init').each(function(){
							
							var map;
							var mapObject = $(this)[0];
							var postcode = encodeURI($(this).data('postcode')).replace(/%20/g, "+");
							var company = encodeURI($(this).data('company')).replace(/%20/g, "+");
							var title = $(this).data('company');
							var zoom = 12;
							
							if ($(this).attr('data-zoom')) {
								zoom = $(this).data('zoom');
							}
							
							function initialize() {
								var mapOptions = {
									zoom: zoom,
									center: new google.maps.LatLng(-34.397, 150.644),
									mapTypeId: google.maps.MapTypeId.ROADMAP,
									draggable: !("ontouchend" in document),
									scrollwheel: false
								};
								var geolocate = function(address, callback) {
									jQuery.ajax({
										url: "https://maps.googleapis.com/maps/api/geocode/json",
										data: {
											"sensor": true,
											"address": address
										},
										dataType: "json",
										success: function(d) {
											if (d.status === "ZERO_RESULTS") { 
												callback(false); 
											} else if (d.results && d.results[0] && d.results[0].geometry) {
												callback({
														"center": d.results[0].geometry.location
												});
											} else { 
												callback(false);
											}
										}
									});
								};
								if(geolocate) {
									map = new google.maps.Map(mapObject, mapOptions);
									geolocate(company+","+postcode+",+United+Kingdom", function(c) {
										map.setCenter(new google.maps.LatLng(c.center.lat, c.center.lng));
										var marker = new google.maps.Marker({
											position: new google.maps.LatLng(c.center.lat, c.center.lng),
											map: map,
											title: title
										});
									});
								}
							}
							initialize();
						});
					});
				}

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$(window).load(function(){
					if( $('body').hasClass('loading') ){
						$('body').removeClass('loading');
					}
				});
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
				$('input[name="preview_on"]').on("change", function(){
					$('.portfolio-carousel').removeClass('preview-mobile');
					$('.portfolio-carousel').removeClass('preview-tablet');
					$('.portfolio-carousel').removeClass('preview-desktop');
					$('.portfolio-carousel').addClass('preview-' + $('input[name="preview_on"]:checked').val());

				});
			}
		},
		'post_type_archive_portfolio': {
			init: function() {
				// JavaScript to be fired on the about us page
				$('.filter .btn-toggle').on("click", function(){
					$btn = $(this);
					if($btn.hasClass('active')){ 
						$btn.removeClass('active');
					} else {
						$btn.addClass('active');
					}

					var showing = 0;

					$('.masonry-grid').find('.portfolio').each(function(){
						$item = $(this);
						$item.removeClass('portfolio').hide();
						$('.filter .btn-toggle').each(function(){
							$filter = $(this);
							if($filter.hasClass('active') && $item.hasClass('portfolio-feature-' + $filter.val())){
								$item.addClass('portfolio').show();
							 	showing = 1;
							}
						});

					});

					if(showing === 0){
						$('.masonry-grid').find('> *').addClass('portfolio').show();
					}

					$('.masonry-grid').masonry();
				});
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		},
		'page_template_template_contact': {
			init: function() {
				
				//Contact form
				$('input[name="name"]').attr('name','inputName'); //Honey Trap

				if(typeof document.createElement( 'input' ).checkValidity === 'function'){
					$('#CFContactForm').find('input:not([type="checkbox]), select').change(function(){
						if($(this)[0].checkValidity()){ 
							$(this).parents('.form-group').addClass('has-success').removeClass('has-error');
						} else {
							$(this).parents('.form-group').addClass('has-error').removeClass('has-success');
						}
					});
				}

				function CF_isValidEmailAddress(emailAddress) {
					var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(emailAddress);
				}

				jQuery('input').change(function(){jQuery('#CFContactForm .error-message').hide();});

				function SF_validateContactForm() {
					if(Modernizr.inputtypes.email && Modernizr.input.required && Modernizr.input.formvalidation){
						return true;
					}
					var valid_email = new CF_isValidEmailAddress(jQuery('#CFContactForm #inputEmail').val());
					if($('#CFContactForm #inputName').val().length < 3) {
						$('#CFContactForm .error-message').show().html('You must enter a valid name');
						$('#CFContactForm #inputName').parents('.form-group').removeClass('has-success').addClass('has-error');
						return false;
					}
					else if(!valid_email) {
						$('#CFContactForm .error-message').show().html('You must enter a valid email address');
						$('#CFContactForm #inputEmail').parents('.form-group').removeClass('has-success').addClass('has-error');
						return false;
					}
					else if($('#CFContactForm #inputMessage').val().length < 10) {
						$('#CFContactForm .error-message').show().html('You must enter a valid message');
						$('#CFContactForm #inputMessage').parents('.form-group').removeClass('has-success').addClass('has-error');
						return false;
					} else { return true; }
				}
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
